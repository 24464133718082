import React from "react";

import "./Experience.scss";

function Experience() {
  return (
    <ul className="experience">
      <li className="position">
        <div className="left">
          <span className="title">Lead Javascript Engineer</span>
          <p className="description">
            I built web UI front and visualisation for a machine learning
            powered market communications intelligence application. On top of
            React, Redux, d3.js front-end and Node.js backend I worked with
            Google Firebase cloud functions to build out real time data
            streaming service.
          </p>
        </div>
        <div className="right">
          <div className="date">October 2020 - June 2021</div>
          <div className="place">comint.ai, LONDON</div>
        </div>
      </li>
      <li className="position">
        <div className="left">
          <span className="title">Senior Javascript Developer</span>
          <p className="description">
            I am working on multiple projects including building the first
            Robo-advisor in the company as well as developing a cross project
            user interface component platform
          </p>
        </div>
        <div className="right">
          <div className="date">April 2017 - October 2020</div>
          <div className="place">DWS / Deutsche Bank, LONDON</div>
        </div>
      </li>
      <li className="position">
        <div className="left">
          <span className="title">Senior Full Stack Engineer</span>
          <p className="description">
            During the contract I was responsible for reshaping UBS UI
            components and platform. I created new solutions for enterprise
            grade application shell and a UI component library
          </p>
        </div>
        <div className="right">
          <div className="date">February 2016 – April 2017</div>
          <div className="place">UBS, LONDON</div>
        </div>
      </li>
      <li className="position">
        <div className="left">
          <span className="title">Senior UI Engineer</span>
          <p className="description">
            Led the development and interface architecture efforts for a
            comprehensive visualisation and data analysis tool and real time
            data plotting
          </p>
        </div>
        <div className="right">
          <div className="date">January 2015 - December 2015</div>
          <div className="place">Precise, LONDON</div>
        </div>
      </li>
      <li className="position">
        <div className="left">
          <span className="title">Senior UI Engineer</span>
          <p className="description">
            Responsible for leading production of web prototypes and web apps
            for major international telecom company.
          </p>
        </div>
        <div className="right">
          <div className="date">July 2014 – January 2015</div>
          <div className="place">Accenture, LONDON</div>
        </div>
      </li>
      <li className="position">
        <div className="left">
          <span className="title">Senior UI Engineer</span>
          <p className="description">
            Worked on a single page application for data capture and analysis on
            a remote basis. Developed a responsive interface that included data
            grids, widgets, complex forms and wizard journeys for configuration.
          </p>
        </div>
        <div className="right">
          <div className="date">May 2014 - July 2014</div>
          <div className="place">Cloudbuzz, LONDON</div>
        </div>
      </li>
      <li className="position">
        <div className="left">
          <span className="title">Principal UI Engineer</span>
          <p className="description">
            Worked on a single page application for data capture and analysis on
            a remote basis. Developed a responsive interface that included data
            grids, widgets, complex forms and wizard journeys for configuration
          </p>
        </div>
        <div className="right">
          <div className="date">September 2013 - April 2014</div>
          <div className="place">Capco, LONDON</div>
        </div>
      </li>
      <li className="position">
        <div className="left">
          <span className="title">Front-end Engineer</span>
          <p className="description">
            Freelance contracts for various tech clients
            <br />
            JudoPay
            <br />
            Nitrogen
            <br />
            TrackMyProgress.com
            <br />
            Animschool.com
            <br />
            SurveyMonkey
            <br />
            LessonArchitect
            <br />
            ePRomer
          </p>
        </div>
        <div className="right">
          <div className="date">January 2009 - September 2013</div>
          <div className="place">MULTIPLE LOCATIONS</div>
        </div>
      </li>
    </ul>
  );
}

export default Experience;
