import React, { useState } from "react";

import imgO2 from "./assets/o2/1.png";
import imgDeutschebank from "./assets/deutschebank/1.png";
import imgUbs from "./assets/ubs/1.png";
import imgKantar from "./assets/kantar/1.png";
import imgCapco from "./assets/capco/1.png";
import imgComintai from "./assets/comintai/1.png";
import imgAnimschool from "./assets/animschool/1.png";
import imgJudo from "./assets/judo/1.png";

import "./style.scss";

function getContent(slide) {
  console.log(slide);
  switch (slide) {
    case 0:
      return imgDeutschebank;
    case 1:
      return imgUbs;
    case 2:
      return imgO2;
    case 3:
      return imgKantar;
    case 4:
      return imgCapco;
    case 5:
      return imgComintai;
    case 6:
      return imgAnimschool;
    case 7:
      return imgJudo;
  }
}


function getStack(slide) {
  console.log(slide);
  switch (slide) {
    case 0:
      return ['web app', 'sdk', 'component library', 'react native mobile app'];
    case 1:
      return ['component sdk', 'application shell', 'global platform development', 'sdk standards'];
    case 2:
      return ['e-commerce web development', 'AB testing', 'user session analytics'];
    case 3:
      return ['data visualisation', 'saas web platform', 'components sdk development'];
    case 4:
      return ['product platform', 'data visualisation', 'configurable dashboards'];
    case 5:
      return ['microservices design', 'data streaming', 'api design', 'natural language processing implementation'];
    case 6:
      return ['e-commerce web development', 'user admin panel', 'reusable components'];
    case 7:
      return ['payment platform development', 'user session analytics', 'A/B testing', 'e-commerce development'];
  }
}

function Portfolio() {
  let [slide, setSlide] = useState(0);

  function onclick(slide, dir) {
    if (dir == "forward") {
      if (slide >= 7) {
        setSlide(0);
      } else {
        setSlide(slide + 1);
      }
    } else {
      if (slide <= 0) {
        setSlide(7);
      } else {
        setSlide(slide - 1);
      }
    }
  }

  const img = getContent(slide);

  return (
    <div className="Portfolio">
      <header>
        <h2>
          <span className="headline-left">Aleksander</span>{" "}
          <span className="headline-right">Sekowski <span className="portfolioSpan">Portfolio</span></span>
        </h2>
      </header>
      <div className="carousel">
        <div className="back" onMouseUp={() => onclick(slide, "back")}></div>
        <div
          className="logo"
          style={{ backgroundPositionY: slide * -100 }}
        ></div>
        <div
          className="forward"
          onMouseUp={() => onclick(slide, "forward")}
        ></div>
      </div>
      <div className="content">
        <div className="content-text">
          <span>scope:</span>{" "}
          {getStack(slide).join(' / ')}
        </div>
        {img && <img src={getContent(slide)} />}
      </div>
    </div>
  );
}

export default Portfolio;
