import React from "react";

import "./Background.scss";

function Background() {
  return (
    <div className="background">
      <div className="left" />
      <div className="right" />
    </div>
  );
}

export default Background;
