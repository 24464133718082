import React from "react";

import "./Socials.scss";

function Socials() {
  return (
    <ul className="socials">
      <li
        className="button medium"
        onClick={() => {
          window.open("https://medium.com/@aleksander-sekowski", "_blank");
        }}
      />
      <li
        className="button medium"
        onClick={() => {
          window.open("https://medium.com/@aleksander-sekowski", "_blank");
        }}
      />
      <li
        className="button github"
        onClick={() => {
          window.open("https://github.com/aleksUIX", "_blank");
        }}
      />
      <li
        className="button linkedin"
        onClick={() => {
          window.open(
            "https://uk.linkedin.com/in/aleksandersekowski/en",
            "_blank"
          );
        }}
      />
      <li
        className="button email"
        onClick={() => {
          window.location.href = "mailto:inbox@uixlimited.com";
        }}
      />
    </ul>
  );
}

export default Socials;
