import React from "react";

import Background from "../../components/Background";
import Socials from "../../components/Socials";
import Experience from "../../components/Experience";

import "./style.scss";

function CV() {
  return (
    <div className="App">
      <header>
        <h1>
          <span className="headline-left">Aleksander</span>{" "}
          <span className="headline-right">Sekowski</span>
          <a href="/portfolio" className="portfolio-link">
            view portfolio
          </a>
        </h1>
        <Socials />
      </header>
      <Experience />
      <Background />
    </div>
  );
}

export default CV;
