import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import CV from "./pages/cv";
import Portfolio from "./pages/portfolio";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <CV />
        </Route>
        <Route path="/portfolio">
          <Portfolio />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
